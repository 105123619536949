.page-header {
    transition: color 0.2s;
    
    body.bg-black-muted &,
    .is-menu-open & {
        color: #fff;
    }
    
    &__bar {
        height: 100px;

        @screen m {
            height: 140px;
        }
        @screen l {
            height: 160px;
        }
    }

    &__logo {
        width: 30px;
        height: 30px;
        top: 40px;

        @screen m {
            width: 50px;
            height: 50px;
            top: 45px;
        }
        @screen l {
            width: 60px;
            height: 60px;
            top: 45px;
        }
    }

    &__menu-toggle {
        top: 40px;
        padding: 0 9px;
        
        @screen m {
            padding: 0 12px;
        }
        
        @screen l {
            top: 60px;
        }
        
        &:after {
            content: '';
            position: absolute;
            display: block;
            height: 40px;
            width: calc(100% + 20px);
            top: -10px;
            left: -10px;
            
            @screen m {
                height: 60px;
            }
        }
        
        .l1, .l2, .l3 {
            @apply block absolute;
            width: 2px;
        }
        
        .l1, .l2 {
            height: 20px;
            top: 0;
            
            @screen m {
                height: 40px;
            }
        }
        
        .l1 {
            left: 0; 
        }
        
        .l2 {
            left: 8px;
            
            @screen m {
                display: none;
                left: 11px;
            }
        }
        
        .l3 {
            height: 60px;
            top: -40px;
            right: 0;
            
            @screen m {
                height: 80px;
            }
            @screen l {
                height: 100px;
                top: -60px;
            }
        }
        
        .t1, .t2 {
            padding: 0 3px;
            
            @screen m {
                padding: 0 5px;
            }
        }
        
        .t1 {
            display: none;
            
            @screen m {
                display: block;
            }
        }
        
        .t2 {
            display: none;
        }
    }
    
    &__menu-toggle-text {
        top: -2px;
        
        @screen m {
            top: 10px;
        }
    }
}
