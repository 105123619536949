@layer base {
    @supports not (font-variation-settings: normal) {
        @font-face {
            font-family: 'Whyte';
            src: url('../fonts/ABCWhyte-Light.woff2') format('woff2'), url('../fonts/ABCWhyte-Light.woff') format('woff');
            font-weight: 300;
        }

        @font-face {
            font-family: 'Whyte';
            src: url('../fonts/ABCWhyte-LightItalic.woff2') format('woff2'), url('../fonts/ABCWhyte-LightItalic.woff') format('woff');
            font-weight: 300;
            font-style: italic;
        }

        @font-face {
            font-family: 'Whyte';
            src: url('../fonts/ABCWhyte-Regular.woff2') format('woff2'), url('../fonts/ABCWhyte-Regular.woff') format('woff');
            font-weight: 400;
        }

        @font-face {
            font-family: 'Whyte';
            src: url('../fonts/ABCWhyte-RegularItalic.woff2') format('woff2'), url('../fonts/ABCWhyte-RegularItalic.woff') format('woff');
            font-weight: 400;
            font-style: italic;
        }

        @font-face {
            font-family: 'Whyte';
            src: url('../fonts/ABCWhyte-Medium.woff2') format('woff2'), url('../fonts/ABCWhyte-Medium.woff') format('woff');
            font-weight: 500;
        }

        @font-face {
            font-family: 'Whyte';
            src: url('../fonts/ABCWhyte-MediumItalic.woff2') format('woff2'), url('../fonts/ABCWhyte-MediumItalic.woff') format('woff');
            font-weight: 500;
            font-style: italic;
        }

        @font-face {
            font-family: 'Whyte';
            src: url('../fonts/ABCWhyte-Bold.woff2') format('woff2'), url('../fonts/ABCWhyte-Bold.woff') format('woff');
            font-weight: 700;
        }
        @font-face {
            font-family: 'Whyte';
            src: url('../fonts/ABCWhyte-BoldItalic.woff2') format('woff2'), url('../fonts/ABCWhyte-BoldItalic.woff') format('woff');
            font-weight: 700;
            font-style: italic;
        }
    }

    @supports (font-variation-settings: normal) {
        @font-face {
            font-family: 'Whyte';
            src: url('../fonts/ABCWhyteVariable.woff2') format('woff2 supports variations'), url('../fonts/ABCWhyteVariable.woff2') format('woff2-variations');
            font-weight: 100 1000;
        }
    }
}

