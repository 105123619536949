/* ==============================
    Basic/generic global styles
 ============================== */

body {
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
}

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    @apply transition-opacity duration-800 ease-out-quad;
    opacity: 1;
}

.no-js {
    img.lazyload {
        display: none;
    }
}
