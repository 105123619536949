/**
========================
Custom Tailwind components
https://tailwindcss.com/docs/extracting-components
========================
 */

@layer components {

    /* Typography styles */
    .h1 {
        @apply font-light text-35 leading-45 m:text-60 m:leading-70 l:text-100 l:leading-110; 
    }
    .ingress {
        @apply font-light text-16 leading-25 m:text-20 m:leading-30 l:text-25 l:leading-40;
    }
    .projects-hover {
        @apply font-light text-12 leading-18 sp:text-16 sp:leading-22 m:text-18 m:leading-24 lp:text-22 lp:leading-32;
    }
    .copy-big {
        @apply text-18 leading-25 m:text-35 m:leading-45 l:text-50 l:leading-65;
    }
    .project-heading {
        @apply text-18 leading-25 m:text-35 m:leading-45;
    }
    .copy {
        @apply font-light text-14 leading-20 m:text-18 m:leading-26;
    }
    .image-caption {
        @apply font-light italic text-12 leading-16 m:text-14 m:leading-20 l:text-18 l:leading-26;
    }
    .teaser-heading {
        @apply font-medium text-20 leading-25 l:text-30 l:leading-40;
    }
    .tag {
        @apply uppercase font-medium tracking-5 text-12 leading-1 m:text-12 l:text-14;
    }
    .quote {
        @apply font-light italic text-20 leading-30 m:text-20 m:leading-30 l:text-30 l:leading-45;
    }
    .link-text {
        @apply font-light text-20 leading-25 l:text-30 l:leading-45;
    }
    
    .top-padding {
        @apply pt-120 m:pt-220;
    }
    
    .variable-heading {
        --ttVariationBoth: "wght" 300, "ital" 0;
        --ttVariationWeight: "wght" 300;
        --ttVariationItalic: "ital" 0;
        
        strong, b {
            font-variation-settings: var(--ttVariationWeight);
        } 
        
        em, i {
            font-variation-settings: var(--ttVariationItalic);
        }
        
        strong>em,
        strong>i,
        b>em,
        b>i {
            font-variation-settings: var(--ttVariationBoth);
        }
        
        div {
            white-space: nowrap;
        }
    }
    
    .image-reveal-wrapper {
        padding-bottom: calc((200/150) * 100%);
        
        @screen sp {
            padding-bottom: calc((300/340) * 100%);
        }
        @screen m {
            padding-bottom: calc((300/416) * 100%);
        }
        @screen l {
            padding-bottom: calc((500/700) * 100%);
        }
        @screen xl {
            padding-bottom: calc((500/850) * 100%);
        }
    }
    
    .accordion-hook {
        button[aria-expanded="true"] & {
            transform: rotate(-180deg);
        }
    }
    
    .block-wrapper > *:first-child {
        margin-top: 0 !important;
    }
    
    .project-text-border {
        &:before {
            content: '';
            
        }
    }
}

