.wysiwyg {
    p, ul, ol, figure {
        margin-bottom: calc((20/14) * 1em);
        
        @screen l {
            margin-bottom: calc((26/18) * 1em);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    strong, b {
        font-weight: 500;
    }

    em, i {
        @supports not (font-variation-settings: normal) {
            font-style: italic;
        }
        @supports (font-variation-settings: normal) {
            font-variation-settings: "ital" 10;
        }
    }

    h2 {
        font-weight: 500;
    }

    a {
        @apply text-blue underline hover:no-underline;

        &[href^="mailto:"]:after,
        &[target="_blank"]:after {
            content: '\00a0↗';
            //font-size: 0.8em;
        }
    }
    
    ul, ol {
        @apply pl-20 border-l-1 border-black;
    }
    
    li {
        
    }
    
    &--list-alt {
        li {
            font-weight: 400;
            padding-top: 2px;
            padding-bottom: 2px;
            
            @screen l {
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }
    }
    
    &--inverted {
        a {
            @apply text-yellow;
        }
        
        ul, ol {
            @apply border-white;
        }
    }
    
    figure {
        position: relative;
        width: 100%;
        aspect-ratio: 16/9 !important;
    }
    
    iframe {
        position: absolute;
        width: 100% !important;
        height: 100% !important;
    }
}
