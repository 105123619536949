.page-footer {
    &__line {
        height: 100%;
        top: 0;
        /*
        height: calc(100% + 50px);
        top: -50px;
        
        @screen m {
            height: calc(100% + 60px);
            top: -60px;
        }
        @screen l {
            height: calc(100% + 80px);
            top: -80px;
        }
        
         */
    }
}
