/**
========================
Custom Tailwind utilities
https://tailwindcss.com/docs/adding-new-utilities
========================

All Tailwind-powered utilities need to render inside the `@layer utilities` directive.
Tailwind will automatically create responsive classes for any utilities rendered inside the `@responsive` directive.
If you need Tailwind to create pseudo-class variant classes (e.g. for :hover or :focus), use the `@variants` directive.
The `@variants` and `@responsive` directives can be combined.

https://tailwindcss.com/docs/adding-new-utilities#generating-responsive-variants
https://tailwindcss.com/docs/adding-new-utilities#generating-pseudo-class-variants

 */

@layer utilities {

    /**

        Værsågod custom Tailwind utilities
        https://tailwindcss.com/docs/adding-custom-styles#adding-custom-utilities

        These utilities are provided via tailwind.config.js:

        .gpu
        .anti
        .round
        .full
        .center
        .center-x
        .center-y
        .clear
        .scrollable
        .scrollable-x
        .scrollbar-hidden

        .wrapper (container with outer margins and max content width)
        .outer-margins (container with outer margins, but no max-width)
        .gutters (gap-based gutters, both horizontal and vertical)
        .gutters-x (gap-based horizontal gutters)
        .gutters-y (gap-based vertical gutters)
        .pad-wrapper (wrapper meant to be used with pad-gutters children, i.e. like the old .g-wrap)
        .pad-gutters (padding-based gutters, both horizontal and vertical)
        .pad-gutters-x (padding-based horizontal gutters)
        .pad-gutters-y (padding-based vertical gutters)
     */
    
    .fullheight {
        height: 100vh;
        @supports (--custom:property) {
            height: calc(var(--vh, 1vh) * 100);
        }
    }

    .placed-wrap {
        position: relative;
        height: 0;
    }

    .placed-image {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    .min-fullheight {
        min-height: 100vh;
        @supports (--custom:property) {
            min-height: calc(var(--vh, 1vh) * 100);
        }
    }

    .object-cover {
        object-fit: cover;
        width: 100%;
        height: 100%;

        html.no-objectfit & {
            object-fit: none !important;
            width: auto;
            height: auto;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: none;
            max-height: none;
            min-width: 100%;
            min-height: 100%;
        }
    }

    .object-contain {
        object-fit: contain;
        width: 100%;
        height: 100%;

        html.no-objectfit & {
            object-fit: none !important;
            width: auto;
            height: auto;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
        }
    }

    .vertical-text {
        writing-mode: vertical-rl;
        transform: rotate(180deg) translateX(0.5em);
    }

    .variable-italic {
        @supports not (font-variation-settings: normal) {
            font-style: italic;
        }
        @supports (font-variation-settings: normal) {
            font-variation-settings: "ital" 10;
        }
    }

    .flex-expand {
        position: relative;
        left: -10px;
        width: calc(100% + 20px) !important;
    }

    .ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;
    }
}
